import { createContext, useState } from "react";

const SplineContext = createContext({
  data: { modelFetched: false },
  setData: () => {},
});

const SplineContextProvider = ({ children }) => {
  const [data, setData] = useState({ modelFetched: false });
  const value = { data, setData };

  return (
    <SplineContext.Provider value={value}>{children}</SplineContext.Provider>
  );
};

export { SplineContext, SplineContextProvider };
