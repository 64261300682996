import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "./pages/error/ErrorPage";
import Root from "./pages/root/Root";

const Home = lazy(() => import("./pages/home/Home"));
const FollowCursor = lazy(() =>
  import("./pages/framer/followCursor/FollowCursor")
);
const Placeholder = lazy(() => import("./components/placeholder/Placeholder"));
const FootballField = lazy(() =>
  import("./pages/spline/footballField/FootballField")
);
// const Services = lazy(() => import("./components/services/Services"));
// const ContactUs = lazy(() => import("./components/contactUs/ContactUs"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "works",
        element: <Placeholder sectionName="Works" />,
      },
      {
        path: "offerings",
        element: <Placeholder sectionName="Offerings" />,
      },
      {
        path: "company",
        element: <Placeholder sectionName="Company" />,
      },
      {
        path: "framer",
        children: [
          {
            path: "follow-cursor",
            element: <FollowCursor />,
          },
          // {
          //   path: "football-kick",
          //   element: <FootballKick />,
          // },
        ],
      },
      {
        path: "spline",
        children: [{ path: "football-field", element: <FootballField /> }],
      },
    ],
  },
]);

export default router;
