import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { Collapse } from "bootstrap";
import { LazyMotion, domAnimation, m } from "framer-motion";
import styles from "./Navbar.module.css";
import { navbarVariants } from "./variants";
const vam_logo = require("../../images/vam_logo_ver1_var2.png");

function Navbar() {
  const [toggle, setToggle] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const navbarOptionsRef = useRef();
  const navbarRef = useRef(null);

  // this effect will handle the switching of the collapsable
  // menu on change of the toggle state.
  useEffect(() => {
    let navbarOptions = navbarOptionsRef.current;
    let bsCollapse = new Collapse(navbarOptions, { toggle: false });
    toggle ? bsCollapse.show() : bsCollapse.hide();
  }, [toggle]);

  // this effect will close the navigation dropdowns when
  // user taps anywhere on the screen.
  useEffect(() => {
    window.addEventListener("mousedown", closeNavbarOptions);
    return () => window.removeEventListener("mousedown", closeNavbarOptions);
  });

  // this effect will handle the show/hide functionality
  // of the navbar on scroll.
  useEffect(() => {
    let controlNavbar = () => {
      if (window.scrollY > 150 && window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setShowNavbar(false);
      } else {
        // if scroll up show the navbar
        setShowNavbar(true);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    };

    window.addEventListener("scroll", controlNavbar);

    // cleanup function
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY]);

  // this function will only allow the toggle state to change
  // if the Collapse animation is not in transition.
  let handleToggle = (toggleState) => {
    let navbarOptions = navbarOptionsRef.current;
    let isTransitioning = Collapse.getInstance(navbarOptions)?._isTransitioning;
    if (!isTransitioning) {
      if (toggleState === undefined) {
        setToggle((toggle) => !toggle);
      } else {
        setToggle(toggleState);
      }
    }
  };

  let closeNavbarOptions = (e) => {
    if (toggle && !navbarRef?.current.contains(e.target)) {
      setToggle(false);
    }
  };

  const navItems = [
    {
      type: "dropdown",
      text: "Labs",
      children: [
        {
          link: "/framer/follow-cursor",
          text: "Follow cursor",
        },
        // {
        //   link: "/framer/football-kick",
        //   text: "Football kick",
        // },
        {
          link: "/spline/football-field",
          text: "Football field",
        },
      ],
    },
    {
      link: "/works",
      text: "Works",
    },
    {
      link: "/offerings",
      text: "Offerings",
    },
    {
      link: "/company",
      text: "Company",
    },
  ];

  return (
    <LazyMotion features={domAnimation}>
      <m.nav
        className={`navbar navbar-expand-md navbar-dark bg-dark ${styles.header}`}
        animate={showNavbar ? "show" : "hide"}
        variants={navbarVariants}
        ref={navbarRef}
      >
        <Link to="/" className={`navbar-brand ${styles.headerLogo}`}>
          <img src={vam_logo} alt="VAM logo" />
        </Link>
        <button
          className={`navbar-toggler ${styles.navbarToggler}`}
          aria-controls="navbarOptions"
          aria-label="Toggle navigation"
        >
          <input
            type="checkbox"
            checked={toggle}
            onChange={() => handleToggle(undefined)}
            id="checkbox"
            className={`${styles.checkbox} ${styles.visuallyHidden}`}
          />
          <label htmlFor="checkbox">
            <div className={`${styles.hamburger}`}>
              <span className={`${styles.bar} ${styles.bar1}`}></span>
              <span className={`${styles.bar} ${styles.bar2}`}></span>
              <span className={`${styles.bar} ${styles.bar3}`}></span>
              <span className={`${styles.bar} ${styles.bar4}`}></span>
              <span className={`${styles.bar} ${styles.bar5}`}></span>
            </div>
          </label>
        </button>
        <div
          id="navbarOptions"
          className={`navbar-collapse collapse justify-content-end`}
          ref={navbarOptionsRef}
        >
          <div className={`navbar-nav`}>
            {navItems.map((item, index) => {
              let navItem;
              if (item.type === "dropdown") {
                navItem = (
                  <div className="dropdown nav-item text-center" key={index}>
                    <button
                      className={`btn btn-secondary dropdown-toggle`}
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {item.text}
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {item.children.map((child, index) => (
                        <li key={index}>
                          <Link to={child.link} className="dropdown-item">
                            {child.text}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              } else {
                navItem = (
                  <div className={`nav-item`} key={index}>
                    <Link
                      to={item.link}
                      onClick={() => handleToggle(false)}
                      className={`nav-link text-center ${styles.navLink}`}
                    >
                      {item.text}
                    </Link>
                  </div>
                );
              }
              return navItem;
            })}
          </div>
        </div>
      </m.nav>
    </LazyMotion>
  );
}

export default Navbar;
