import { RouterProvider } from "react-router-dom";
import router from "./router";
import TagManager from "react-gtm-module";
import { SplineContextProvider } from "./context/SplineContext";

const tagManagerArgs = {
  gtmId: "GTM-WT996MWX",
};

TagManager.initialize(tagManagerArgs);
function App() {
  window.dataLayer.push({
    event: "pageview",
  });
  return (
    <SplineContextProvider>
      <main>
        <RouterProvider router={router} />
      </main>
    </SplineContextProvider>
  );
}

export default App;
