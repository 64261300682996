import Navbar from "../../components/navbar/Navbar";

function ErrorPage() {
  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <h3 style={{ paddingTop: "var(--navbar-height)" }}>
          Path doesn't exist
        </h3>
      </div>
    </>
  );
}

export default ErrorPage;
