export const navbarVariants = {
  show: {
    opacity: 1,
    // top: 0,
    transition: { duration: 0.5 },
  },
  hide: {
    opacity: 0,
    // top: "calc(var(--navbar-height) * -1)",
    transition: { duration: 0.5 },
  },
};
